import React from "react";
import {PropagateLoader} from "react-spinners";

// Countries names internationalized
export const countryNameInLang = require("i18n-iso-countries");
countryNameInLang.registerLocale(require("i18n-iso-countries/langs/en.json"));
countryNameInLang.registerLocale(require("i18n-iso-countries/langs/fr.json"));

// Colors
export const green_kamix = '#5E923D';
export const yellow_kamix = '#FFC820';
export const red_warning_kamix = '#fb6340';

// Remote call limit page
export const remote_call_limit = 100;
export const default_threshold = 50;

// Spinner use to wait the mount of components
export const firstSpinner = (height,size) =>
  <>
    <div className="d-flex justify-content-center align-items-center" style={{height:height != null ? height : "50vh"}}>
      <PropagateLoader
        sizeUnit={"px"}
        size={size != null ? size : 10}
        color={green_kamix}
      />
    </div>
  </>;

// Format number to 1K, 1M, ...
export const formatter = Intl.NumberFormat('en', { notation: 'compact' });

// Strong password regex
export const strongPassword = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@*?#_\-=+]).{4,}$/);
export const hasUpperCase = RegExp(/[A-Z]/);
export const hasWhiteSpace = RegExp(/\s/);

// Languages constants
export const _app_name = 'app_name';
export const _kamix_logo = 'kamix_logo';
export const _log_in = 'log_in';
export const _log_in_again = 'log_in_again';
export const _my_account = 'my_account';
export const _logout = 'logout';

export const _error_message_default = 'error_message_default';
export const _error_message_default_2 = 'error_message_default_2';
export const _error_message_not_online = 'error_message_not_online';
export const _sign_in_to_your_account = 'sign_in_to_your_account';
export const _username = 'username';
export const _password = 'password';
export const _welcome_message_with_user_name = 'welcome_message_with_user_name';
export const _welcome_message_without_user_name = 'welcome_message_without_user_name';
export const _goodbye = 'goodbye';
export const _support = 'support';
export const _loading = 'loading';
export const _x_tickets_awaiting_response = 'x_tickets_awaiting_response';
export const _of = 'of';
export const _no_discussion_for_the_moment = 'no_discussion_for_the_moment';
export const _title = 'title';
export const _solved = 'solved';
export const _infos = 'infos';
export const _mark_as_solved = 'mark_as_solved';
export const _manage_user = 'manage_user';
export const _load_messages_text = 'load_messages_text';
export const _photo = 'photo';
export const _video = 'video';
export const _user = 'user';
export const _admin = 'admin';
export const _file_size_warning = 'file_size_warning';
export const _warning_sending_text_message = 'warning_sending_text_message';
export const _ticket_id = 'ticket_id';
export const _ticket_information = 'ticket_information';
export const _browser_video_tag_alert = 'browser_video_tag_alert';
export const _send = 'send';
export const _back = 'back';
export const _mark_ticket_as_solved_question = 'mark_ticket_as_solved_question';
export const _delete_message_question = 'delete_message_question';
export const _delete = 'delete';
export const _user_id = 'user_id';
export const _ticket_marked_as_solved = 'ticket_marked_as_solved';
export const _english = 'english';
export const _french = 'french';
export const _language = 'language';
export const _we_have_a_problem = 'we_have_a_problem';
export const _back_to_home_page = 'back_to_home_page';
export const _you_are_lost = 'you_are_lost';
export const _page_not_found = 'page_not_found';
export const _enter_some_text = 'enter_some_text';
export const _missing_text_to_search = 'missing_text_to_search';
export const _enter_a_value = 'enter_a_value';
export const _select_an_attribute = 'select_an_attribute';
export const _select_a_list = 'select_a_list';
export const _no_search_parameters_entered = 'no_search_parameters_entered';
export const _users = 'users';
export const _no_users_found = 'no_users_found';
export const _home = 'home';
export const _dashboard = 'dashboard';
export const _full_names = 'full_names';
export const _phone_number = 'phone_number';
export const _phone_numbers = 'phone_numbers';
export const _country = 'country';
export const _manage = 'manage';
export const _registration_date = 'registration_date';
export const _actions = 'actions';
export const _kamix_balances = 'kamix_balances';
export const _no_kamix_balance_to_present = 'no_kamix_balance_to_present';
export const _yes = 'yes';
export const _no = 'no';
export const _account_deleted = 'account_deleted';
export const _kid = 'kid';
export const _email_address = 'email_address';
export const _my_blockchain_addresses = 'my_blockchain_addresses';
export const _no_profile_yet = 'no_profile_yet';
export const _user_profile = 'user_profile';
export const _verification_status = 'verification_status';
export const _profile_status = 'profile_status';
export const _no_blockchain_address = 'no_blockchain_address';
export const _extractions = 'extractions';
export const _no_extraction_found = 'no_extraction_found';
export const _name = 'name';
export const _created_by = 'created_by';
export const _status = 'status';
export const _started_on = 'started_on';
export const _completed_on = 'completed_on';
export const _id = 'id';
export const _list_to_extract = 'list_to_extract';
export const _list_type = 'list_type';
export const _recipients = 'recipients';
export const _recipients_emails = 'recipients_emails';
export const _launch_an_extraction = 'launch_an_extraction';
export const _extraction_name = 'extraction_name';
export const _no_email_added = 'no_email_added';
export const _add_an_email_address = 'add_an_email_address';
export const _warning_valid_email_address = 'warning_valid_email_address';
export const _cancel = 'cancel';
export const _launch = 'launch';
export const _extraction_launch_successfully = 'extraction_launch_successfully';
export const _extraction_launch_in_progress = 'extraction_launch_in_progress';
export const _match_percentage = 'match_percentage';
export const _view = 'view';
export const _sub_name_checked = 'sub_name_checked';
export const _potential_match_detected = 'potential_match_detected';
export const _rows_per_page = 'rows_per_page';
export const _error_encountered = 'error_encountered';
export const _no_element_found = 'no_element_found';
export const _aka = 'aka';
export const _middle_name = 'middle_name';
export const _type = 'type';
export const _administrators = 'administrators';
export const _list = 'list';
export const _roles = 'roles';
export const _no_administrator_created_yet = 'no_administrator_created_yet';
export const _create_an_administrator = 'create_an_administrator';
export const _administrator_roles_management = 'administrator_roles_management';
export const _create_a_role = 'create_a_role';
export const _no_role_created_yet = 'no_role_created_yet';
export const _created_at = 'created_at';
export const _no_action_assigned_to_this_role = 'no_action_assigned_to_this_role';
export const _operation_performed = 'operation_performed';
export const _delete_element_warning = 'delete_element_warning';
export const _edit = 'edit';
export const _creating_a_role = 'creating_a_role';
export const _editing_a_role = 'editing_a_role';
export const _role_name_warning_1 = 'role_name_warning_1';
export const _role_name_warning_2 = 'role_name_warning_2';
export const _name_to_display_in_french = 'name_to_display_in_french';
export const _name_to_display_in_english = 'name_to_display_in_english';
export const _unique_name = 'unique_name';
export const _create = 'create';
export const _modify = 'modify';
export const _missing_information = 'missing_information';
export const _first_name = 'first_name';
export const _last_name = 'last_name';
export const _link_to_list = 'link_to_list';
export const _role = 'role';
export const _create_an_admin = 'create_an_admin';
export const _phone_number_not_valid = 'phone_number_not_valid';
export const _email_address_not_valid = 'email_address_not_valid';
export const _username_not_valid = 'username_not_valid';
export const _ok = 'ok';
export const _default_generated_password_message = 'default_generated_password_message';
export const _select_a_role = 'select_a_role';
export const _active = 'active';
export const _inactive = 'inactive';
export const _updated_at = 'updated_at';
export const _search_for_a_user = 'search_for_a_user';
export const _enter_user_kid = 'enter_user_kid';
export const _quick_search = 'quick_search';
export const _information = 'information';
export const _restore = 'restore';
export const _delete_user_account_question = 'delete_user_account_question';
export const _delete_admin_account_question = 'delete_admin_account_question';
export const _restore_user_account_question = 'restore_user_account_question';
export const _restore_admin_account_question = 'restore_admin_account_question';
export const _account_restored = 'account_restored';
export const _validate = 'validate';
export const _invalidate = 'invalidate';
export const _devices = 'devices';
export const _no_device_yet = 'no_device_yet';
export const _can_do_transactions = 'can_do_transactions';
export const _last_seen = 'last_seen';
export const _main = 'main';
export const _set_as_main = 'set_as_main';
export const _notes = 'notes';
export const _create_a_note = 'create_a_note';
export const _no_item_yet = 'no_item_yet';
export const _new_note = 'new_note';
export const _save = 'save';
export const _documents = 'documents';
export const _add_text_to_note = 'add_text_to_note';
export const _created = 'created';
export const _message = 'message';
export const _note_modification = 'note_modification';
export const _editable = 'editable';
export const _tagged = 'tagged';
export const _logs = 'logs';
export const _no_log_found = 'no_log_found';
export const _device_name = 'device_name';
export const _date = 'date';
export const _url = 'url';
export const _method = 'method';
export const _status_code = 'status_code';
export const _device_id = 'device_id';
export const _ip_address = 'ip_address';
export const _ip_addresses = 'ip_addresses';
export const _ip_address_details = 'ip_address_details';
export const _no_ip_addresses_yet = 'no_ip_addresses_yet';
export const _nb_of_connection = 'nb_of_connection';
export const _details = 'details';
export const _los_verification = 'los_verification';
export const _pep_verification = 'pep_verification';
export const _entry = 'entry';
export const _threshold = 'threshold';
export const _launched_by = 'launched_by';
export const _result = 'result';
export const _user_kid = 'user_kid';
export const _source = 'source';
export const _overwrite = 'overwrite';
export const _make = 'make';
export const _edition = 'edition';
export const _reset_password = 'reset_password';
export const _change_password = 'change_password';
export const _actual_password = 'actual_password';
export const _new_password = 'new_password';
export const _confirm_password = 'confirm_password';
export const _must_match_new_password = 'must_match_new_password';
export const _password_constraint_text = 'password_constraint_text';
export const _action_detail = 'action_detail';
export const _welcome = 'welcome';
export const _no_user_profile_found = 'no_user_profile_found';
export const _calculated_at = 'calculated_at';
export const _configurations = 'configurations';
export const _result_details = 'result_details';
export const _position = 'position';
export const _entry_with_best_match = 'entry_with_best_match';
export const _nationality = 'nationality';
export const _country_of_residence = 'country_of_residence';
export const _profile_level = 'profile_level';
export const _profile_risk = 'profile_risk';
export const _profile_risk_2 = 'profile_risk_2';
export const _profile_score = 'profile_score';
export const _kyc_required = 'kyc_required';
export const _documents_to_provide_or_under_verification = 'documents_to_provide_or_under_verification';
export const _manage_profile = 'manage_profile';
export const _recalculate = 'recalculate';
export const _user_identity = 'user_identity';
export const _user_proof_of_identity = 'user_proof_of_identity';
export const _user_incomes_info = 'user_incomes_info';
export const _user_quota = 'user_quota';
export const _user_cguv_accept = 'user_cguv_accept';
export const _verified_at = 'verified_at';
export const _click_to_modify = 'click_to_modify';
export const _birth_date = 'birth_date';
export const _birth_place = 'birth_place';
export const _zip_code = 'zip_code';
export const _living_address = 'living_address';
export const _living_city = 'living_city';
export const _living_country = 'living_country';
export const _immigrant = 'immigrant';
export const _in_europe = 'in_europe';
export const _in_sepa = 'in_sepa';
export const _accepted = 'accepted';
export const _rejected = 'rejected';
export const _annual_incomes_range = 'annual_incomes_range';
export const _socio_professional_category = 'socio_professional_category';
export const _activity_area = 'activity_area';
export const _quota = 'quota';
export const _reset = 'reset';
export const _verification_service = 'verification_service';
export const _format = 'format';
export const _download = 'download';
export const _load_a_menu_explanation = 'load_a_menu_explanation';
export const _extracted_data = 'extracted_data';
export const _old_profiles = 'old_profiles';
export const _kyc_name = 'kyc_name';
export const _lcbft_verification = 'lcbft_verification';
export const _launch_user_name_comparisons = 'launch_user_name_comparisons';
export const _choose_type_of_marking_to_be_done = 'choose_type_of_marking_to_be_done';
export const _los = 'los';
export const _pep = 'pep';
export const _ds = 'ds';
export const _comment = 'comment';
export const _kyc_additional = 'kyc_additional';
export const _add_an_element = 'add_an_element';
export const _not_uploaded_yet = 'not_uploaded_yet';
export const _warning = 'warning';
export const _warning_file_upload_1 = 'warning_file_upload_1';
export const _warning_file_upload_2 = 'warning_file_upload_2';
export const _warning_file_upload_3 = 'warning_file_upload_3';
export const _warning_file_upload_4 = 'warning_file_upload_4';
export const _choose_file = 'choose_file';
export const _file_selected = 'file_selected';
export const _click_to_download = 'click_to_download';
export const _extracted = 'extracted';
export const _provided = 'provided';
export const _marked = 'marked';
export const _transactions = 'transactions';
export const _bank_transfer = 'bank_transfer';
export const _bank_transfers = 'bank_transfers';
export const _wallet = 'wallet';
export const _done_at = 'done_at';
export const _amount = 'amount';
export const _to = 'to';
export const _bank_transfer_deleted = 'bank_transfer_deleted';
export const _bank_transfer_id = 'bank_transfer_id';
export const _transfer_reference = 'transfer_reference';
export const _user_bank_account_number = 'user_bank_account_number';
export const _user_bank_name = 'user_bank_name';
export const _kamix_bank_name = 'kamix_bank_name';
export const _supply_verb = 'supply_verb';
export const _user_bank_country = 'user_bank_country';
export const _freeze = 'freeze';
export const _transfer_status = 'transfer_status';
export const _balance_sheets = 'balance_sheets';
export const _balance = 'balance';
export const _payments = 'payments';
export const _purchases = 'purchases';
export const _subscriptions = 'subscriptions';
export const _difference = 'difference';
export const _transfers_in = 'transfers_in';
export const _transfers_out = 'transfers_out';
export const _summary_by_crypto = 'summary_by_crypto';
export const _crypto_name = 'crypto_name';
export const _refunds = 'refunds';
export const _real_balance = 'real_balance';
export const _balance_result_infos = 'balance_result_infos';
export const _balance_sheet = 'balance_sheet';
export const _general_information = 'general_information';
export const _limits = 'limits';
export const _transaction_mode = 'transaction_mode';
export const _transaction_type = 'transaction_type';
export const _limits_per_transaction = 'limits_per_transaction';
export const _sliding_limits = 'sliding_limits';
export const _min = 'min';
export const _max = 'max';
export const _days = 'days';
export const _element_deleted = 'element_deleted';
export const _mode = 'mode';
export const _sender = 'sender';
export const _receiver = 'receiver';
export const _transaction_code = 'transaction_code';
export const _to_be_validated = 'to_be_validated';
export const _tx_to_be_validated = 'tx_to_be_validated';
export const _ended_at = 'ended_at';
export const _other_information = 'other_information';
export const _matrix_verification = 'matrix_verification';
export const _scenario_based_verification = 'scenario_based_verification';
export const _points = 'points';
export const _launch_matrix_check = 'launch_matrix_check';
export const _launch_scenario_based_verification = 'launch_scenario_based_verification';
export const _description = 'description';
export const _decision_making = 'decision_making';
export const _decision = 'decision';
export const _cancel_tx = 'cancel_tx';
export const _mark = 'mark';
export const _supply_the_balance = 'supply_the_balance';
export const _transaction_information = 'transaction_information';
export const _additional_information = 'additional_information';
export const _amount_in_xaf = 'amount_in_xaf';
export const _identity_documents = 'identity_documents';
export const _page = 'page';
export const _loading_pdf_text = 'loading_pdf_text';
export const _managed_by = 'managed_by';
export const _value = 'value';
export const _edit_config = 'edit_config';
export const _reason = 'reason';
export const _empty_field = 'empty_field';
export const _risk_profile_2023 = 'risk_profile_2023';
export const _risk_profile_2021 = 'risk_profile_2021';
export const _risk_scenarios_outside_pr_2023 = 'risk_scenarios_outside_pr_2023';
export const _calculate_user_risk_profile = 'calculate_user_risk_profile';
export const _successful_operation_kamix_email = 'successful_operation_kamix_email';
export const _risk_level = 'risk_level';
export const _vigilance_level = 'vigilance_level';
export const _historical_calculation_risk_profiles = 'historical_calculation_risk_profiles';
export const _must_be_recalculated = 'must_be_recalculated';
export const _triggered_by_tx = 'triggered_by_tx';
export const _already_added = 'already_added';
export const _create_a_ticket = 'create_a_ticket';
export const _select_user_first = 'select_user_first';
export const _enter_search_value = 'enter_search_value';
export const _category = 'category';
export const _tx_type = 'tx_type';
export const _response = 'response';
export const _variables = 'variables';
export const _label = 'label';
export const _options = 'options';
export const _opt_default = 'opt_default';
export const _alert_question = 'alert_question';
export const _true = 'true';
export const _false = 'false';
export const _not_connected_message = 'not_connected_message';
export const _total_numbers_of_points = 'total_numbers_of_points';
export const _calculated_by = 'calculated_by';
export const _see_more = 'see_more';
export const _edit_rule = 'edit_rule';
export const _messaging = 'messaging';
export const _momo_name = 'momo_name';
export const _last_used = 'last_used';
export const _blocked = 'blocked';
export const _recipient_deleted = 'recipient_deleted';
export const _relationship = 'relationship';
export const _block_recipient_question = 'block_recipient_question';
export const _unblock_recipient_question = 'unblock_recipient_question';
export const _block = 'block';
export const _unblock = 'unblock';
export const _edit_momo_name = 'edit_momo_name';
export const _compose = 'compose';
export const _tags = 'tags';
export const _footer = 'footer';
export const _template = 'template';
export const _none = 'none';
export const _preview = 'preview';
export const _template_created_message = 'template_created_message';
export const _message_body_empty = 'message_body_empty';
export const _models = 'models';
export const _broadcasts = 'broadcasts';
export const _select_template = 'select_template';
export const _alerts = 'alerts';
export const _origin = 'origin';
export const _reply = 'reply';
export const _response_type = 'response_type';
export const _last_admin_who_handled_alert = 'last_admin_who_handled_alert';
export const _alert_details = 'alert_details';
export const _alert_details_infos = 'alert_details_infos';
export const _indications = 'indications';
export const _risk_profile_from_which_alert_originated = 'risk_profile_from_which_alert_originated';
export const _rule_name = 'rule_name';
export const _rule_number = 'rule_number';
export const _matrix_alert_title = 'matrix_alert_title';
export const _scenario_alert_title = 'scenario_alert_title';
export const _transaction = 'transaction';
export const _matrix_line = 'matrix_line';
export const _scenario = 'scenario';
export const _responses = 'responses';
export const _responded_by = 'responded_by';
export const _responded_at = 'responded_at';
export const _message_to_user = 'message_to_user';
export const _use = 'use';
export const _selected_model_presentation = 'selected_model_presentation';
export const _input_source = 'input_source';
export const _input_sources = 'input_sources';
export const _find_someone_question = 'find_someone_question';
export const _select_file = 'select_file';
export const _broadcast_message_created = 'broadcast_message_created';
export const _broadcast_message_modified = 'broadcast_message_modified';
export const _stop_broadcast_question = 'stop_broadcast_question';
export const _launch_broadcast_question = 'launch_broadcast_question';
export const _stop = 'stop';
export const _create_model = 'create_model';
export const _create_diffusion = 'create_diffusion';
export const _modify_model = 'modify_model';
export const _modify_diffusion = 'modify_diffusion';
export const _alert_id = 'alert_id';
export const _special_list_name = 'special_list_name';
export const _users_involved = 'users_involved';
export const _report = 'report';
export const _target = 'target';
export const _target_name = 'target_name';
export const _sanctions_update_db = 'sanctions_update_db';
export const _sanctions_start_screening = 'sanctions_start_screening';
export const _sanctions_screen_on_basis_of_name = 'sanctions_screen_on_basis_of_name';
export const _sanctions_perform_screening_of_user = 'sanctions_perform_screening_of_user';
export const _sanctions_perform_screening_of_recipient = 'sanctions_perform_screening_of_recipient';
export const _pep_update_db = 'pep_update_db';
export const _pep_start_screening = 'pep_start_screening';
export const _pep_screen_on_basis_of_name = 'pep_screen_on_basis_of_name';
export const _pep_perform_screening_of_user = 'pep_perform_screening_of_user';
export const _pep_perform_screening_of_recipient = 'pep_perform_screening_of_recipient';
export const _full_name_to_screen = 'full_name_to_screen';
export const _first_names_to_screen = 'first_names_to_screen';
export const _last_names_to_screen = 'last_names_to_screen';
export const _search_detection_message = 'search_detection_message';
export const _positive_screening_verdict = 'positive_screening_verdict';
export const _number_of_recipient_being_searched = 'number_of_recipient_being_searched';
export const _db_version = 'db_version';
export const _verdict_admin = 'verdict_admin';
export const _verdict_date = 'verdict_date';
export const _giving_verdict = 'giving_verdict';
export const _final_screening_verdict = 'final_screening_verdict';
export const _date_on_inclusion_in_sanction_list = 'date_on_inclusion_in_sanction_list';
export const _list_name = 'list_name';
export const _last_update_date = 'last_update_date';
export const _kyc = 'kyc';
export const _update = 'update';
export const _pending = 'pending';
export const _all_alerts = 'all_alerts';
export const _pending_alerts = 'pending_alerts';
export const _all = 'all';
export const _name_comparison = 'name_comparison';
export const _form_status = 'form_status';
export const _manage_profiles_v3 = 'manage_profiles_v3';
export const _manage_profiles_v2 = 'manage_profiles_v2';
export const _risk_profile = 'risk_profile';
export const _match = 'match';
export const _alert_type = 'alert_type';
export const _sandbox = 'sandbox';
export const _create_fictitious_user = 'create_fictitious_user';
export const _create_fictitious_tx_history = 'create_fictitious_tx_history';
export const _user_created = 'user_created';
export const _nb_fake_tx_to_created = 'nb_fake_tx_to_created';
export const _amount_min = 'amount_min';
export const _amount_max = 'amount_max';
export const _date_min = 'date_min';
export const _date_max = 'date_max';
export const _level_of_risk_requiring_this_kyc = 'level_of_risk_requiring_this_kyc';
export const _add = 'add';
export const _add_additional_kyc_to_user = 'add_additional_kyc_to_user';
export const _select_document_type = 'select_document_type';
export const _document_name = 'document_name';
export const _launch_los_verification = 'launch_los_verification';
export const _launch_pep_verification = 'launch_pep_verification';
export const _x_details = 'x_details';
export const _credit_card = 'credit_card';
export const _credit_cards = 'credit_cards';
export const _card_number = 'card_number';
export const _bank_name = 'bank_name';
export const _bank_country = 'bank_country';
export const _history_user_profiles = 'history_user_profiles';
export const _action = 'action';
export const _blocking_nature_alert = 'blocking_nature_alert';
export const _blocking_nature_alert_min = 'blocking_nature_alert_min';
export const _profile = 'profile';
export const _uploaded_by = 'uploaded_by';
export const _uploaded_documents = 'uploaded_documents';
export const _document_id = 'document_id';
export const _update_metadata = 'update_metadata';
export const _update_extracted_data = 'update_extracted_data';
export const _metadata = 'metadata';
export const _document_number = 'document_number';
export const _issue_country = 'issue_country';
export const _issue_date = 'issue_date';
export const _expiry_date = 'expiry_date';
export const _amount_value = 'amount_value';
export const _name_to_display = 'name_to_display';
export const _delete_data_question = 'delete_data_question';
export const _non_blocking = 'non_blocking';
export const _all_blocking = 'all_blocking';
export const _archives = 'archives';
export const _empty_folder = 'empty_folder';
export const _size = 'size';
export const _custom = 'custom';
export const _payments_services = 'payments_services';
export const _telegram = 'telegram';
export const _bot = 'bot';
export const _connect_to_kamix_payments_services = 'connect_to_kamix_payments_services';
export const _go_to_kamix_payments_services = 'go_to_kamix_payments_services';
export const _create_one_time_password_telegram = 'create_one_time_password_telegram';
export const _retry = 'retry';
export const _otp_password_created_telegram = 'otp_password_created_telegram';
export const _upload_documents = 'upload_documents';
export const _field = 'field';
export const _identity_document = 'identity_document';
export const _visage = 'visage';
export const _select_files = 'select_files';
export const _suspicion_reports = 'suspicion_reports';
export const _create_declaration_of_suspicion = 'create_declaration_of_suspicion';
export const _contents = 'contents';
export const _creation = 'creation';
export const _change_status = 'change_status';
export const _no_content_to_display_yet = 'no_content_to_display_yet';
export const _status_edition = 'status_edition';
export const _score = 'score';
export const _total_score = 'total_score';
export const _total = 'total';
export const _submitted_by = 'submitted_by';
export const _submitted_at = 'submitted_at';
export const _add_content = 'add_content';
export const _text = 'text';
export const _select_a_file = 'select_a_file';
export const _added_by = 'added_by';
export const _added_at = 'added_at';
export const _document = 'document';
export const _provided_by = 'provided_by';
export const _extracted_data_provided_by_user = 'extracted_data_provided_by_user';
export const _extracted_data_provided_by_admin = 'extracted_data_provided_by_admin';
export const _no_input_data = 'no_input_data';
export const _screenings = 'screenings';
export const _screening_history = 'screening_history';
export const _sanctions = 'sanctions';
export const _dos = 'dos';
export const _pep_2 = 'pep_2';
export const _manage_bank_transfer = 'manage_bank_transfer';
export const _warning_operation_irreversible = 'warning_operation_irreversible';
export const _risk_profile_aml_factory = 'risk_profile_aml_factory';
export const _id_kyc_file_at_aml_factory = 'id_kyc_file_at_aml_factory';
export const _kyc_file_reference = 'kyc_file_reference';
export const _aml_administrator_id = 'aml_administrator_id';
export const _is_ppe = 'is_ppe';
export const _is_included_in_sanctions_list = 'is_included_in_sanctions_list';
export const _data_sheet_kyc_aml_factory = 'data_sheet_kyc_aml_factory';
export const _status_information = "status_information";
export const _mode_information = "mode_information";
export const _executed_at = 'executed_at';
export const _credit_card_holder_name = "credit_card_holder_name";
export const _blockchain_name = "blockchain_name";
export const _blockchain_hash = "blockchain_hash";
export const _blockchain_sender_address = "blockchain_sender_address";
export const _blockchain_receiver_address = "blockchain_receiver_address";
export const _rollback_pending = "rollback_pending";
export const _rollback_done = "rollback_done";
export const _rollback_information = "rollback_information";
export const _finalization_information = "finalization_information";
export const _finalization_id = "finalization_id";
export const _finalization_service = "finalization_service";
export const _view_transaction = "view_transaction";
export const _launch_finalization = "launch_finalization";
export const _cancel_finalization = "cancel_finalization";
export const _kamix_payments_service = "kamix_payments_service";
export const _classic_auto_momo = "classic_auto_momo";
export const _refresh = "refresh";
export const _return_transaction = "return_transaction";
export const _cancel_reason = "cancel_reason";
export const _transaction_finalization = "transaction_finalization";
export const _finalization_cancellation = "finalization_cancellation";
export const _transaction_cancellation = "transaction_cancellation";
export const _optional = "optional";
export const _fees = "fees";
export const _finalizations_list = "finalizations_list";
export const _to_be_finalized = "to_be_finalized";
export const _card_expiry_date = "card_expiry_date";
export const _card_service = "card_service";
export const _card_holder_name = "card_holder_name";
export const _card_issuer = "card_issuer";
export const _card_issuer_country = "card_issuer_country";
export const _card_fingerprint = "card_fingerprint";
export const _card_payment_3ds = "card_payment_3ds";
export const _credit_cards_payments = "credit_cards_payments";
export const _client_onboarding_link = "client_onboarding_link";
export const _client_dotfile_case_link = "client_dotfile_case_link";
export const _copy = "copy";
export const _open = "open";
export const _copy_successful = "copy_successful";
export const _accommodation_type = "accommodation_type";
export const _linked_to_usa = "linked_to_usa";
export const _device_information = "device_information";
export const _temporary = "temporary";
export const _dotfile_actions = "dotfile_actions";
export const _dotfile_share_onboarding_link = "dotfile_share_onboarding_link";
export const _dotfile_open_case = "dotfile_open_case";
export const _dotfile_synchronize_case = "dotfile_synchronize_case";
export const _dotfile_onboarding_link_copy_successful = "dotfile_onboarding_link_copy_successful";
export const _status_message = "status_message";
export const _dotfile_open_case_full = "dotfile_open_case_full";
export const _dotfile_synchronize_case_full = "dotfile_synchronize_case_full";
export const _surname = "surname";
export const _receiver_id = "receiver_id";
export const _name_updated_at = "name_updated_at";
export const _operator = "operator";
export const _transfer_receivers = "transfer_receivers";
export const _deletion_reason = "deletion_reason";
export const _deletion_date = "deletion_date";
export const _deleted_by = "deleted_by";
export const _aml_factory_push_transactions_successful = "aml_factory_push_transactions_successful";
export const _aml_factory_push_transactions = "aml_factory_push_transactions";
export const _aml_factory_last_pushed_transaction = "aml_factory_last_pushed_transaction";
export const _last_activity_date = "last_activity_date";
export const _clients_list = "clients_list";
export const _prospects_list = "prospects_list";
export const _closed_list = "closed_list";
export const _verified_by = "verified_by";
export const _account_blocked = "account_blocked";
export const _blocked_at = "blocked_at";
export const _blocked_by = "blocked_by";
export const _unblock_account = "unblock_account";
export const _block_account = "block_account";
export const _user_category = "user_category";
export const _user_category_closed = "user_category_closed";
export const _user_category_client = "user_category_client";
export const _user_category_prospect = "user_category_prospect";
export const _profile_status_incomplete = "prf_stat_incomplete";
export const _profile_status_filled = "prf_stat_filled";
export const _profile_status_accepted = "prf_stat_accepted";
export const _profile_status_rejected = "prf_stat_rejected";
export const _account_status_active = "acc_stat_active";
export const _account_status_pending = "acc_stat_pending";
export const _account_status_closed = "acc_stat_closed";
export const _account_status_blocked = "acc_stat_blocked";
export const _old_version = "old_version";
export const _add_metadata = "add_metadata";
export const _remove_metadata = "remove_metadata";
export const _kyc_analysis_prefix = "kyc_analysis_prefix";
export const _kyc_analysis_approved = "kyc_analysis_approved";
export const _kyc_analysis_rejected = "kyc_analysis_rejected";
export const _kyc_analysis_error = "kyc_analysis_error";
export const _real_incomes_value = "real_incomes_value";
export const _reaL_assets_value = "reaL_assets_value";
export const _iban = "iban";
export const _bic = "bic";
export const _no_finalization_yet_message = "no_finalization_yet_message";
export const _payment_provider = "payment_provider";
export const _perform_disbursement = "perform_disbursement";
export const _disbursement_verb = "disbursement_verb";
export const _disbursement_all_balance = "disbursement_all_balance";
export const _disbursements = "disbursements";
export const _login_2fa_title = 'login_2fa_title';
export const _login_2fa_indication = 'login_2fa_indication';
export const _otp_password_label = 'otp_password_label';
export const _otp_password_resend = 'otp_password_resend';
export const _inactivity_signout = 'inactivity_signout';
export const _otp_password_resend_success = "otp_password_resend_success";
export const _page_background_color = 'page_background_color';
export const _message_content = 'message_content';
export const _gray = 'gray';
export const _orange_yellow = 'orange_yellow';
export const _select_an_item = 'select_an_item';
export const _add_an_item = 'add_an_item';
export const _media_library = 'media_library';
export const _no_media_to_display_yet = 'no_media_to_display_yet';
export const _close = 'close';
export const _select_a_media = 'select_a_media';
export const _media_selected = 'media_selected';
export const _make_button_visible = 'make_button_visible';
export const _still_elements_to_fill_in = 'still_elements_to_fill_in';
export const _view_more = 'view_more';
export const _enter_button_title = 'enter_button_title';
export const _enter_redirection_link = 'enter_redirection_link';
export const _ = '';

